// NotFoundScreen.js
const NotFoundScreen = () => {
  return (
    <div className='mt-5 pt-4'>
      <h1>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundScreen;
